/*
 * @Author: 992526373@qq.com
 * @Date: 2022-08-18 11:56:55
 * @LastEditTime: 2022-08-31 13:26:44
 * @Description:
 */
import { axiosOption } from './request';
import request from './request';

interface ILoginParams {
    username: string | number;
    password: string | number;
}

interface IAddArticle {
    title: string;
    content: string;
}

interface IEditArticle extends IAddArticle {
    id: number;
}

interface IPaginate {
    skip: number;
    take: number;
}

interface IUserRole {
    username: string;
    player: string;
}

// 注册
export const RegisterApi = (params: ILoginParams) => request.post('/register', params);

// 登录
export const LoginApi = (params: ILoginParams) => request.post('/login', params);

// 获取用户信息
export const UserInfoApi = () => request.get('/info');

// 获取用户列表
export const UserListApi = () => request.get('/user');

// 更新用户权限
export const UserRoleApi = (params: IUserRole) => request.post('/user/role', params);

// 获取图文教程列表
export const ArticleListApi = () => request.get('/article');

// 获取图文教程列表（分页）
export const ArticleListPaginateApi = (params: IPaginate) => request.post('/article', params);

// 根据id获取教程详情
export const getArticleByIdApi = (params: { id: number }) => request.get(`/article/id/${params.id}`);

// 编辑文章
export const editArticleApi = (params: IEditArticle) => request.post('/article/edit', params);

// 添加文章
export const addArticleApi = (params: IAddArticle) => request.post('/article/add', params);

// 删除文章
export const deleteArticleApi = (params: { id: number }) => request.post('/article/delete', params);

// 文章图片接口
export const articleImageApi = `${axiosOption.baseURL}/upload`;

// 获取数据集列表（分页）
export const LibraryListPaginateApi = (params: IPaginate) => request.post('/library', params);

// 更新数据集数据
export const updateLibraryApi = (params: { id: number; tags: string }) => request.post('/library/update', params);

// 获取联系表单列表（分页）
export const ContactListPaginateApi = (params: IPaginate) => request.post('/contact', params);

// 删除联系表单
export const deleteContactApi = (params: { id: number }) => request.post('/contact/delete', params);

// 获取eps表单列表（分页）
export const EpsApplyListPaginateApi = (params: IPaginate) => request.post('/epsapply', params);

// 删除eps系表单
export const deleteEpsApplyApi = (params: { id: number }) => request.post('/epsapply/delete', params);
